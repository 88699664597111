<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">SMS 관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div>
          <div class="form-inp sm">
            <v-select
                v-model="aspCustKey"
                :items="aspCustList"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                placeholder="선택하세요"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
                v-model="CNSL_DIV"
                :items="dropcnslDivItems"
                item-text="title"
                outlined hide-details
                placeholder="선택하세요"
                label="분류구분"
            >
              <template v-slot:label>
                분류구분
              </template>
            </v-select>
          </div>
          <div class="form-inp ml-2">
            <v-text-field
                class="form-inp full"
                name="SMS_TIT"
                label="SMS명"
                outlined
                hide-details
                v-model="SMS_TIT"
                @keyup.enter="searchSmsList"
            ></v-text-field>
          </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="searchSmsList"> 조회 </v-btn>
        </div>
      </div>
    </div>
    <!-- SMS 목록 -->
    <div class="d-flex">
      <div class="box-wrap col-5">
        <h2 class="tit-h2 d-flex">SMS 목록</h2>
        <div class="box-ct">
          <v-data-table
              dense
              :headers="gridDataHeaders"
              :items="gridDataText"
              item-key="ROW_NUMBER"
              hide-default-footer
              class="grid-default"
              height="150"
              single-select
              @click:row="getSmsDetail"
              fixed-header
              :item-class="isActiveRowScp"
          >
          </v-data-table>
        </div>
        <div class="mt-5">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnUnfold')" outlined class="btn-point" @click="unfoldTreeview">전체펼치기</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnFold')" outlined class="btn-default ml-2" @click="foldTreeview">전체접기</v-btn>
        </div>
        <div class="mt-5">
          <div class="tree-wrap mt-2 scrollable" style="height: 389px">
            <template>
              <v-treeview
                  :items="treeItems"
                  item-disabled="locked"
                  activatable
                  open-on-click
                  item-key="id"
                  ref="treeview1"
              >
                <template v-slot:prepend="{ open, item }" >
                  <v-icon color="#8B98DD" v-if="item.children">
                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                  </v-icon>
                </template>

                <template v-slot:label="{ item }">
                  <a @click="getItemDetail(item)">{{ item.name }}</a>
                  <v-icon color="#F06969" >
                    {{ item.locked ? 'mdi-close-circle-outline' : '' }}
                  </v-icon>
                </template>
              </v-treeview>
            </template>
          </div>
        </div>
      </div>
      <!-- SMS 상세 -->
      <div class="box-wrap ml-4 flex-grow-1">
        <h2 class="tit-h2 d-flex">SMS상세
          <div class="absolute-h2-btn">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnHighAdd')" outlined class="btn-default" v-on:click="clickFirstnodeAdd">최상위신규</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" outlined class="btn-default ml-2" v-on:click="clickAdd">신규</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" :disabled="!isActive" v-on:click="common_confirm('상위SMS 삭제 시 하위 SMS도 삭제됩니다. 삭제하시겠습니까?',clickDelete,'',null,'','error') ">삭제</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" :disabled="!isActive" v-on:click="common_confirm('저장하시겠습니까?',clickSave, '', null,'','done') ">저장</v-btn>
          </div>
        </h2>
        <div class="box-ct">
          <div class="table-form">
            <table>
              <caption class="hide">
                항목
              </caption>
              <colgroup>
                <col width="127px" />
                <col width="" />
                <col width="127px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">상위SMS명</th>
                  <td colspan="3">
                    <v-text-field
                        class="form-inp full"
                        v-model="upperSmsNm"
                        name="upperSmsNm"
                        outlined
                        hide-details
                        disabled
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">SMS명</v-badge>
                  </th>
                  <td colspan="3">
                    <v-text-field
                        class="form-inp full"
                        v-model="thisSmsNm"
                        name="thisSmsNm"
                        label=""
                        outlined
                        hide-details
                        :disabled="!isActive"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">사용여부</v-badge>
                  </th>
                  <td class="">
                    <div class="form-inp full">
                      <v-select
                          v-model="selectedDrop"
                          :items="useYnDrop"
                          item-text="text"
                          item-value="value"
                          @input="setSelected"
                          @change="changeSelected($event)"
                          outlined
                          placeholder="선택하세요"
                          hide-details
                          return-object
                          :disabled="!isActive"
                      ></v-select>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">정렬순서</v-badge>
                  </th>
                  <td class="">
                    <v-text-field
                        class="form-inp full mt-0"
                        type="number"
                        v-model="ordSeq"
                        name="ordSeq"
                        value="0"
                        hide-details
                        :disabled="!isActive"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">발송문구</v-badge>
                  </th>
                  <td colspan="3">
                    <v-textarea
                        class="textarea-type0"
                        height="465px"
                        v-model="smsDesc"
                        label=""
                        no-resize
                        outlined
                        :disabled="!isActive"
                        :rules="textareaRules"
                        :value="textareaValue"
                    ></v-textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import DialogVueDropzone from "@/components/VueDropzone.vue";
import api from "@/store/apiUtil.js";

export default {
  mixins: [mixin],
  name: "MENU_M110801", //name은 'MENU_' + 파일명 조합

  components: {},
  data() {
    return {      
      aspCustKey: "",
      selectedDrop: [],
      CNSL_DIV: "",
      USE_SRCH_YN: "",
      useYnDrop: [
        {text:"Y", value:"Y"},{text:"N", value:"N"}
      ],
      dropcnslDivItems: [
        { title: "전체", value: "" },
        { title: "대분류", value: "1" },
        { title: "중분류", value: "2" },
        { title: "소분류", value: "3" },
      ],
      alertMsg: {
        comChk: "회사구분란에서 회사를 선택 후 이용해주십시오.",
      },
      alertIcon: {
        noti: "svg-done-lg",
        err: "svg-error-lg",
      },

      alertType: {
        default: "default",
        confirm: "confirm",
      },

      treeItems: [],
      gridDataHeaders: [
        { text: "No", align: "center", value: "ROW_NUMBER", width: "70px", sortable: true, },
        { text: "대분류명", value: "SMS_NM_1", align: "left", sortable: true },
        { text: "중분류명", value: "SMS_NM_2", align: "left", sortable: true },
        { text: "소분류명", value: "SMS_NM_3", align: "left", sortable: true },
        { text: "SMS명", value: "SMS_TIT", align: "left", sortable: true },
        { text: "사용여부", value: "USE_YN", align: "center", sortable: true },
      ],
      gridDataText: [],      
      gridFileText: [],
      gridFileSelected: {},
      smsDesc: "",
      textareaRules: [v => v.length <= 80 || '80자 제한'],
      textareaValue: '80자 제한!',
      upperSmsNm: "",
      ordSeq: 0,
      clickedData: [],
      newSmsAdd: false,
      thisSmsNm: "",
      useYnSmsNm: "",
      ordSeqSmsNm: "",
      inputState : "",
      
      SMS_ID:'',
      SMS_TIT:'',
    }

  },
  methods: {
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      console.log('저장');
      this[`dialog${type}`] = false;
    },
    unfoldTreeview() {
      this.$refs.treeview1.updateAll(true);
    },
    foldTreeview() {
      this.$refs.treeview1.updateAll(false);
    },
    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    // 목록에서 클릭 시 상세 정보 출력
    async getSmsDetail(item, row){
      this.SMS_ID = item.SMS_ID;

      let detailUrl = "/api/phone/sms/manage/detail";      
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = detailUrl;
      requestData.headers["SERVICE"] = "phone.sms.manage";
      requestData.headers["METHOD"] = "detail";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey;
      requestData.sendData["SMS_ID"] = item.SMS_ID;

      let response = await this.common_postCall(requestData);
      await this.setSmsDetail(response);      
    },
    isActiveRowScp(item){
      const activeClass = item.SMS_ID === this.SCP_ID ? 'active' : '';
      return activeClass;
    },
    async setSmsDetail(response){
      let data = response.DATA;
      console.log("data", data);

      if( data.length == 1 ){
        // 신규를 누르거나 했을때 부모로 가져오기 위해서 데이터를 가지고 있음
        this.clickedData = data;

        this.upperSmsNm = data[0].UPPER_SMS_NM; // 상위SMS명
        this.thisSmsNm = data[0].SMS_TIT;       // SMS명
        this.useYnSmsNm = data[0].USE_YN;      // 사용 여부
        this.ordSeqSmsNm = data[0].ORD_SEQ;     // 정렬 순서

        this.ordSeq = data[0].ORD_SEQ;          // 정렬순서
        this.smsDesc = data[0].SMS_RMK;         // SMS내용  
        
        this.setSelected(data[0].USE_YN);
        this.inputState = "M";
      }
    },
    async searchSmsList(){
      // 회사구분 필수 입력
      if (this.aspCustKey == null || this.aspCustKey == "") {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: this.alertMsg.comChk,
          iconClass: this.alertIcon.err,
          type: this.alertType.default,
        });
        return false;
      }

      // SMS 목록 조회
      let smsListUrl = "/api/phone/sms/manage/list";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = smsListUrl;
      requestData.headers["SERVICE"] = "phone.sms.manage.list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey;    //회사코드      
      requestData.sendData["CNSL_DIV"] = this.CNSL_DIV;             //분류구분            
      requestData.sendData["SMS_TIT"] = this.SMS_TIT;               //SMS명
      
      let response = await this.common_postCall(requestData);
      this.setSmsList(response);
      this.getTreeviewList();
      this.clickedData = [];
      this.upperSmsNm = "";      
      this.thisSmsNm = "";
      this.ordSeq = 1;
      this.useYnSmsNm = "";
      this.ordSeqSmsNm = "";
      this.smsDesc = "";
      this.clickedData = [];
      this.newSmsAdd = false;
      this.inputState = "";
    },
    // SMS 목록 set해주기
    setSmsList(response){
      let header = response.HEADER;
      let data = response.DATA;

      if( header.ERROR_FLAG != true && parseInt(header.COUNT) > 0 ){
        this.gridDataText = data;
        for(var i = 0; i < data.length; i++){          
          let element = {
            id: data[i].C_NODE_NO,
            pid: data[i].P_NODE_NO,
            name: data[i].SMS_TIT,
            nodeLvl: data[i].NODE_LVL,
            children:[],
          };
        }
        //console.log(data);
      } else {
        this.gridDataText = [];
      }
    },
    async getTreeviewList(){
      // SMS 트리뷰 조회
      let smsListUrl = "/api/phone/sms/manage/tr/manage";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = smsListUrl;
      requestData.headers["SERVICE"] = "phone.sms.manage.tr.manage";
      requestData.headers["METHOD"] = "manage";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["CNSL_DIV"] = this.CNSL_DIV;           //업무구분
      requestData.sendData["SMS_TIT"] = this.SMS_TIT;     //처리구분

      let response = await this.common_postCall(requestData);
      await this.setTreeviewList(response);
      this.unfoldTreeview();
    },
    async setTreeviewList(response){ // 트리뷰를 항목에 맞게 set해준다.
      let header = response.HEADER;
      let data = response.DATA;
      /*
        {
          id: 15,
          name: 'menu3 :',
          children: [
            { id: 16, name: 'menu3-1' },
            { id: 17, name: 'menu3-2' },
            { id: 18, name: 'menu3-3' },
          ],
        },
      */
      let items = [];
      if( header.ERROR_FLAG != true && parseInt(header.COUNT) > 0 ){

        if(this.CNSL_DIV == ""){ // 분류구분을 전체로 했을때 모든 트리뷰가 나오게

          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SMS_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };

            if( element.nodeLvl == 2 ){
              for(var j = 0; j < items.length; j++){
                if( element.pid == items[j].id ){
                  //console.log(`push complate ${items[j].id}`);
                  items[j].children.push(element);
                }
              }
            } else if( element.nodeLvl == 3 ){
              for(var k = 0; k < items.length; k++){
                for(var l = 0; l < items[k].children.length; l++){
                  if( element.pid == items[k].children[l].id ){
                    //console.log(`push complate ${items[k].children[l].id}`);
                    items[k].children[l].children.push(element);
                  }
                }
              }
            } else {
              items.push(element);
            }
          }
        } else if(this.CNSL_DIV == 1){ // 분류 구분이 대분류라면 트리뷰에서 대분류만 나오게
          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SMS_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };
            items.push(element);

          }
        } else if(this.CNSL_DIV == 2){ // 분류 구분이 중분류라면 트리뷰에서 중분류만 나오게
          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SMS_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };
            items.push(element);

          }
        } else if(this.CNSL_DIV == 3){ // 분류 구분이 소분류라면 트리뷰에서 소분류만 나오게
          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SMS_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };
            items.push(element);

          }
        }

        this.treeItems = items;
        //console.log(this.treeItems);
      } else {
        this.treeItems = [];
      }

    },
    // 트리뷰에서 각 폴더, 파일모양을 클릭했을 때
    async getItemDetail(item){
      // SMS 트리뷰 조회
      let detailUrl = "/api/phone/sms/manage/detail";
      let fileUrl = "";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = detailUrl;
      requestData.headers["SERVICE"] = "phone.sms.manage.detail";
      requestData.headers["METHOD"] = "manage";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["SMS_ID"] = item.id;     //처리구분

      let response = await this.common_postCall(requestData);
      //console.log(response);
      await this.setItemDetail(response);


    },
    async setItemDetail(response){
      let data = response.DATA;
      /*
        ASP_CUST_KEY: "001"
        ASP_NEWCUST_KEY: "001"
        BEGIN_DATE: "20200917"
        EOT_DATE: "29991231"
        FILE_GROUP_KEY: ""
        LVL_NO: "1"
        ORD_SEQ: "1"
        SMS_ID: "0002"
        SMS_RMK: ""
        SMS_TIT: "업무지원 SMS"
        UPPER_SMS_ID: ""
        UPPER_SMS_NM: ""
        USE_YN: "Y"
      */
      /*
         smsDesc: "",
         upperSmsNm: "",
         thisSmsNm: "",
         ordSeq: 0,
      */
      //console.log(data);
      if( data.length == 1 ){
        // 신규를 누르거나 했을때 부모로 가져오기 위해서 데이터를 가지고 있음
        this.clickedData = data;

        this.upperSmsNm = data[0].UPPER_SMS_NM; // 상위SMS명
        this.thisSmsNm = data[0].SMS_TIT;       // SMS명
        this.useYnSmsNm = data[0].USE_YN;         // 사용 여부
        this.ordSeqSmsNm = data[0].ORD_SEQ;        // 정렬 순서

        this.ordSeq = data[0].ORD_SEQ;                // 정렬순서
        this.smsDesc = data[0].SMS_RMK;         // SMS내용

        this.setSelected(data[0].USE_YN);
        this.inputState = "M";
        //console.log(this.useYnDrop[0]);        // 사용여부

      }
    },
    setSelected(val){
      this.selectedDrop = { text: val, value: val };
    },

    changeSelected(e){
      //console.log(e);
      this.selectedDrop = {text: e.text, value: e.value};
    },
    clickFirstnodeAdd(){
      if(this.aspCustKey == undefined || this.aspCustKey == ""){
        this.common_alert("회사구분란에서 회사를 선택 후 이용해주십시오.", "error");
        return ;
      }
      this.upperSmsNm = "";
      this.thisSmsNm = "";
      this.ordSeq = 1;
      this.smsDesc = "";
      this.clickedData = [];
      this.inputState = "N";
    },
    clickAdd(){
      if( this.clickedData.length == 0 ) {
        this.common_alert("선택한 SMS가 없습니다.", "error");
        return false;
      } else if( this.clickedData[0].LVL_NO > 2 ){
        this.common_alert("소분류 밑으로는 추가할 수 없습니다.", "error");
        return false;
      }

      this.upperSmsNm = this.clickedData[0].SMS_TIT;
      this.newSmsAdd = true;
      this.thisSmsNm = "";
      this.ordSeq = 1;
      this.smsDesc = "";
      this.inputState = "N";
    },
    async clickSave(){
      if(this.inputState == ""){
        this.common_alert("저장하실 수 없습니다.","error");
        return false;
      }

      if(this.thisSmsNm == null || this.thisSmsNm == ''){
        this.common_alert("SMS명을 입력해주세요.","error");
        return;
      }

      if(this.selectedDrop.value == null || this.selectedDrop.value == ''){
        this.common_alert("사용여부를 선택해주세요,","error");
        return
      }

      let registUrl = "/api/phone/sms/manage/regist";
      let updateUrl = "/api/phone/sms/manage/modify";

      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;

      if(this.newSmsAdd){
        // 신규 클릭 후 저장시
        requestData.sendData["UPPER_SMS_ID"] = this.clickedData[0].SMS_ID;
        requestData.sendData["LVL_NO"] = parseInt(this.clickedData[0].LVL_NO) + 1;

        requestData.headers["URL"] = registUrl;
        requestData.headers["SERVICE"] = "phone.sms.manage.regist";
        requestData.headers["METHOD"] = "post";
      } else if( this.clickedData.length > 0 ){
        // 수정시
        requestData.sendData["UPPER_SMS_ID"] = this.clickedData[0].UPPER_SMS_ID;
        requestData.sendData["LVL_NO"] = this.clickedData[0].LVL_NO;
        requestData.sendData["SMS_ID"] = this.clickedData[0].SMS_ID;
        requestData.headers["URL"] = updateUrl;
        requestData.headers["SERVICE"] = "phone.sms.manage.modify";
        requestData.headers["METHOD"] = "modify";
      } else {
        // 최상위신규 클릭시
        requestData.headers["URL"] = registUrl;
        requestData.headers["SERVICE"] = "phone.sms.manage.regist";
        requestData.sendData["UPPER_SMS_ID"] = "";
        requestData.sendData["LVL_NO"] = "1";
      }

      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["SMS_TIT"] = this.thisSmsNm;
      requestData.sendData["SMS_RMK"] = this.smsDesc;
      requestData.sendData["USER_ID"] = this.$store.getters['userStore/GE_USER_ID'];
      requestData.sendData["ORD_SEQ"] = this.ordSeq;
      requestData.sendData["USE_TY"] = this.USE_NEWTY;
      requestData.sendData["USE_YN"] = this.selectedDrop.value;
      requestData.sendData["CHNG_REQ"] = 'SUCCESS';
      //console.log(requestData);
      //return false;
      let response = await this.common_postCall(requestData);

      if( response.HEADER.ERROR_FLAG != true ){
        this.common_alert(response.HEADER.ERROR_MSG, "done");
      }

      this.clearScreen();

    },

    async clickDelete(){
      if( this.clickedData.length > 0 ){

        let deleteUrl = "/api/phone/sms/manage/delete";
        let requestData = {
          headers: {},
          sendData:{}
        };

        requestData.headers["URL"] = deleteUrl;
        requestData.headers["SERVICE"] = "phone.sms.manage.delete";
        requestData.headers["METHOD"] = "delete";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = true;

        requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
        requestData.sendData["SMS_ID"] = this.clickedData[0].SMS_ID;

        let response = await this.common_postCall(requestData);
        console.log(response);
        if( response.HEADER.ERROR_FLAG != true ){
          this.common_alert(response.HEADER.ERROR_MSG, "done");
        }

        this.clearScreen();

      } else {
        this.common_alert("선택한 항목이 없습니다.", "error");
      }
    },
    clearScreen(){
      this.searchSmsList();
      this.getTreeviewList();
      this.upperSmsNm = "";
      let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
      this.detailDateRange = today + " ~ " + today;
      this.thisSmsNm = "";
      this.ordSeq = 1;
      this.smsDesc = "";
      this.clickedData = [];
      this.newSmsAdd = false;

      this.gridFileText = [];
      this.inputState="";
    },
    success(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '정상처리 되었습니다.',
        iconClass: 'svg-done-lg',
        type: 'default'
      })
    },
  },
  computed: {
    isActive(){
      let rtn = false;
      if(this.inputState != ""){
        rtn = true;
      }else{
        rtn = false;
      }
      return rtn
    },
    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
    },
    async mounted() {
      // this.searchSmsList();
      // this.getTreeviewList();
      // let codeName = ["SCP001"];
      // let common_code = await this.mixin_common_code_get_all(codeName);
      // this.SEARCH_USE_TY = [...this.SEARCH_USE_TY, ...common_code[0].group_value];
      // this.USE_TY = common_code[0].group_value;
  }
};

</script>